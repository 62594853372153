// @flow strict

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import useFetch from 'o365/common/hooks/useFetch';
import FormWrap from 'o365/common/FormWrap';
import { ApiRoutes } from 'o365/common/Routes';
import { FormDataContext } from 'o365/context/FormData';
import { Icon } from 'components/common';

const Default = ({ value }) => (
  <>{value} <small>(default)</small></>
);

Default.propTypes = {
  value: PropTypes.string.isRequired,
};

const StepReview = ({ onSubmit }) => {
  const [formError, setFormError] = useState(null);
  const { formData } = useContext(FormDataContext);

  const throttleEnabled = !!formData.o365ThrottleEnabled?.value;
  const storeFullMessage = !!formData.o365StoreFullMessage?.value;

  const [saveInput, setSaveInput] = useFetch(
    null,
    onSubmit,
    'POST',
    {
      name: formData.o365Name.value,
      content_types: formData.contents.value.split(','),
      polling_interval: formData.pollingInterval.value,
      polling_time_unit: 'MINUTES',
      o365_tenant_id: formData.tenantId.value,
      enable_throttling: throttleEnabled,
      store_full_message: storeFullMessage,
      o365_client_id: formData.clientId.value,
      o365_client_secret: formData.clientSecret.value,
      endpoint_type: formData.subscriptionType.value,
    },
  );

  useEffect(() => {
    setSaveInput(null);

    if (saveInput.error) {
      setFormError({
        full_message: saveInput.error,
        nice_message: <span>We were unable to save your Input, please try again in a few moments.</span>,
      });
    }
  }, [saveInput.error]);

  const handleSubmit = () => {
    setSaveInput(ApiRoutes.INTEGRATIONS.O365.SAVE_INPUT);
  };

  return (
    <FormWrap onSubmit={handleSubmit}
              buttonContent="Save and Start Input"
              loading={saveInput.loading}
              error={formError}
              description="Check out everything below to make sure it&apos;s correct, then click the button below to complete your O365 setup!">

      <Container>
        <Subheader>Input Configuration</Subheader>
        <ReviewItems>
          <li>
            <strong>Name</strong>
            <span>{formData.o365Name.value}</span>
          </li>
          <li>
            <strong>Subscribed To</strong>
            <span>{formData.contents.value}</span>
          </li>
          <li>
            <strong>Subscription Plan</strong>
            <span>{formData.subscriptionType.value}</span>
          </li>
          <li>
            <strong>Enable Throttling</strong>
            <span><Icon name={throttleEnabled ? 'check' : 'times'} /></span>
          </li>
          <li>
            <strong>Store Full Message</strong>
            <span><Icon name={storeFullMessage ? 'check' : 'times'} /></span>
          </li>
        </ReviewItems>
      </Container>
    </FormWrap>
  );
};

StepReview.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const Container: StyledComponent<{}, ThemeInterface, HTMLDivElement> = styled.div(({ theme }) => css`
  border: 1px solid ${theme.colors.variant.darkest.default};
  margin: 25px 0;
  padding: 15px;
  border-radius: 4px;
`);

const Subheader = styled.h3`
  margin: 0 0 10px;
`;

const ReviewItems = styled.ul(({ theme }) => css`

  list-style: none;
  margin: 0 0 25px 10px;
  padding: 0;

  li {
    line-height: 2;
    padding: 0 5px;

    :nth-of-type(odd) {
      background-color: ${theme.colors.table.backgroundAlt};
    }
  }

  strong {
    ::after {
      content: ':';
      margin-right: 5px;
    }
  }
`);

export default StepReview;
